<template>
  <div class="page-content">
    <div class="register-content">
      <form>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <h1 class="page-header">容压器列表 <small></small></h1>
            <vxe-toolbar ref="xToolbar" :loading="loading" custom zoom>
              <template #buttons>
                <vxe-button
                  status="primary"
                  icon="fa fa-plus"
                  content="新增"
                  @click="editEvent"
                ></vxe-button>
              </template>
            </vxe-toolbar>
            <vxe-table
              round
              border
              :height="tabelHeight"
              :loading="loading"
              :data="tableData"
              ref="xTable"
              :column-config="{ resizable: true }"
            >
              <vxe-column
                field="code"
                title="优先级"
                width="200"
                align="center"
              ></vxe-column>
              <vxe-column
                field="name"
                title="标题"
                min-width="150"
                align="left"
              >
                <template #default="{ row }">
                  <a href="#" @click="editEvent(row)"> {{ row.name }} </a>
                </template></vxe-column
              >

              <vxe-column title="操作" fixed="right" width="300" align="center">
                <template #default="{ row }">
                  <vxe-button
                    size="small"
                    class="m-r-5"
                    icon="fa fa-wrench"
                    status="primary"
                    @click="editEvent(row)"
                    >编辑
                  </vxe-button>
                  <vxe-button
                    size="small"
                    icon="fa fa-ban"
                    status="danger"
                    @click="disabledEvent(row)"
                    >禁用</vxe-button
                  >
                  <vxe-button
                    size="small"
                    status="warning"
                    icon="fa fa-trash-alt"
                    @click="removeRowEvent(row)"
                    >删除</vxe-button
                  >
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { dictApi } from "@/api/system/dictApi";
import Pressurevessel from "./Pressurevessel.vue";
export default {
  data() {
    return {
      loading: false,
      tabelHeight: document.body.clientHeight - 200,
      tableData: [],
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
    };
  },
  created() {},
  mounted() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
    let that = this;
    window.onresize = () => {
      return (() => {
        that.tabelHeight = document.body.clientHeight - 200;
      })();
    };
    that.getDictUrl();
  },

  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "删除成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },

    //进入容压器跳出框页面
    editEvent(row) {
      this.$modal.show(
        Pressurevessel,
        { id: row.id },
        { draggable: true },
        { "before-close": this.beforeClose }
      );
    },
    // 跳出窗关闭后返回事件
    beforeClose() {
      this.getDictUrl();
    },
    //获取安全检查列表数据
    getDictUrl() {
      let that = this;
      that.loading = true;
      dictApi
        .getDictUrl({
          // 分页入参
          id: "3a01a6a4-e976-402d-cd14-d2c50688e89b",
          bChild: "true",
        })
        .then((res) => that.GetSuccess(that, res))
        .finally(() => {
          that.loading = false;
        });
    },
    // 获取成功
    GetSuccess(that, res) {
      if (res.success) {
        that.tableData = res.data;
        that.tablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    //
    removeRowEvent(row) {
      this.$swal({
        // title: "Are you sure?",
        text: "是否确定删除?",
        type: "info",
        icon: "warning",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "primary" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            if (row.id) {
              dictApi
                .DeleteDictUrl({ id: row.id })
                .then((res) => that.DeleteDictUrlSuccess(that, res, row))
                .finally(() => {});
            }
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    disabledEvent(row) {
      this.$swal({
        // title: "Are you sure?",
        text: "是否确定禁用此容压器?",
        type: "info",
        icon: "error",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "primary" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            if (row.id) {
              dictApi
                .UpdateDictUrl({
                  id: row.id,
                  pid: row.pid,
                  Name: row.name,
                  Code: row.code,
                  Value: "false",
                  Json: row.json,
                  Note: row.note,
                })
                .then((res) => that.UpdateDictUrlSuccess(that, res))
                .finally(() => {});
            }
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    UpdateDictUrlSuccess(that, res) {
      console.log(that, res);
    },
    DeleteDictUrlSuccess(that, res, row) {
      if (res.success) {
        // Api请求成功之后前端页面删除该行
        const $table = this.$refs.xTable;
        $table.remove(row);
        this.ToastSuccess(res);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.GetSecurityCheckList();
    },
  },
};
</script>
